@media only screen and (max-width: 767px) {

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .section-title {
        span {
            font-size: 14px;
        }
        h2 {
            font-size: 25px;
        }
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 0 0 0 0;

                span {
                    display: block;
                    background: #000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
            top: -12px;//asly---
            z-index: -2;//asly
        }
        .mean-nav {
            margin-top: 39px;
        }
    }
    .others-option-for-responsive {
        .dot-menu {
            top: -26px;
        }
        .container {
            .container {
                left: 20px;
                max-width: 255px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
            }  
        }   
    } 
    .search-overlay {
        &.search-popup {
            right: -10px;
        }
    }
    
    .single-services {
        padding: 30px 20px 48px 20px;

        h3 {
            font-size: 22px;
            margin-bottom: 14px;
        }
        &::before {
            bottom: -94%;
            transform: rotate(-8deg);
        }
    }
    
    .single-portfolio {
        .portfolio-content {
            i {
                line-height: 35px;
            }
            h3 {
                font-size: 22px;
            }
        }
    }
  
    
    .single-blog {
        .content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .pr-5, .px-5 {
        padding-right: 0 !important;
    }
    .single-footer-widget {
        h3 {
            font-size: 22px;
        }
    }
    .copyright-area-content {
        text-align: center;
    }
    .copyright-area {
        .copyright-area-content {
            ul {
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    
    
  
    .single-services-item {
        .content h3 {
            font-size: 22px;
        }
    }
    .single-portfolio-item {
        .portfolio-content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .single-team {
        .image {
            .content {
                h3 {
                    font-size: 22px;
                }
            }
        }
    } 
   
  
    .single-services-box {
        padding: 25px 20px 30px 20px;

        h3 {
            font-size: 22px;
        }
        .icon {
            i {
                height: 100px;
                width: 100px;
                line-height: 100px;
                font-size: 30px;
            }
        }
        &::before {
            bottom: -95%;
        }
    }
   
     
   
   
    .single-features-box {
        &.top-1 {
            margin-top: 0;
        }
        &.top-4 {
            margin-top: 0;
        }
    }
  
    .overview-content {
        span {
            font-size: 14px;
        }
        h3 {
            font-size: 22px;
        }
    }
    .overview-image {
        text-align: center;
        margin-top: 30px;
    }
    .page-banner-area {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    .page-banner-content {
        h2 {
            font-size: 30px;
        }
    }
    .services-details-desc {
        h3 {
            font-size: 22px;
        }
      
        .text-result {
            h3 {
                font-size: 22px;
            }
        }
        .services-details-faq {
            .accordion {
                .accordion-title {
                    font-size: 16px;
                }
            }
        }
    }
 
    .portfolio-details-information {
        margin-top: 30px;
    }
    .blog-details-desc {
        .article-content {
            h3 {
                font-size: 22px;
            }
        }
        .article-footer {
            .article-tags {
                flex: unset;
                max-width: unset;
            }
            .article-share {
                flex: unset;
                max-width: unset;
                margin-top: 25px;
            }
        }
    }
    .widget-area {
        margin-top: 40px;
    }
   
     
    .login-form {
        padding: 30px 20px;

        h2 {
            font-size: 25px;
        }
        form {
            .lost-your-password {
                text-align: right;
                margin-top: 10px;
            }
        }
    }
   
 
   
    .error-content {
        h3 {
            font-size: 25px;
        }
    }
    .rainz-grid-sorting {
        .ordering {
            text-align: center;
            margin-top: 20px;
        }
    }     
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .pt-100 {
        padding-top: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 0 0 0 0;

                span {
                    display: block;
                    background: #000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
            top: -12px;//asly
        }
        .mean-nav {
            margin-top: 39px;
        }
    }
    .others-option-for-responsive {
        .dot-menu {
            top: -26px;
        }
        .container {
            .container {
                left: 20px;
                max-width: 255px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
            }  
        }   
    } 
    .search-overlay {
        &.search-popup {
            left: -10px;
        }
    }
     
    
     
    .single-services {
        padding: 30px 20px 48px 20px;

        &::before {
            bottom: -94%;
            transform: rotate(-8deg);
        }
        h3 {
            font-size: 20px;
        }
    }
    
    .pr-5, .px-5 {
        padding-right: 0 !important;
    }
    .single-footer-widget {
        h3 {
            font-size: 22px;
        }
    }
    .copyright-area-content {
        text-align: right;
    }
    .copyright-area {
        .copyright-area-content {
            ul {
                text-align: left;
            }
        }
    } 
    .single-services-box {
        padding: 25px 20px 30px 20px;

        h3 {
            font-size: 22px;
        }
        .icon {
            i {
                height: 100px;
                width: 100px;
                line-height: 100px;
                font-size: 30px;
            }
        }
        &::before {
            bottom: -95%;
        }
    } 
    .single-features-box {
        &.top-1 {
            margin-top: 0;
        }
        &.top-4 {
            margin-top: 0;
        }
    } 
    .overview-image {
        text-align: center;
        margin-top: 30px;
    }
  
   
    .portfolio-details-information {
        margin-top: 30px;
    }
    .widget-area {
        margin-top: 40px;
    } 
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 0 0 0 0;

                span {
                    display: block;
                    background: #000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
            padding-top: 0;
            top: -12px;//asly
        }
        .mean-nav {
            margin-top: 39px;
        }
    }
    .others-option-for-responsive {
        .dot-menu {
            top: -26px;
        }
        .container {
            .container {
                left: 20px;
                max-width: 255px;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
            }  
        }   
    } 
    .search-overlay {
        &.search-popup {
            left: -10px;
        }
    } 
 
    .single-services {
        padding: 30px 10px 48px 10px;

        &::before {
            bottom: -94%;
            transform: rotate(-8deg);
        }
        h3 {
            font-size: 20px;
        }
    }
    
    .single-services-item {
        .content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .single-portfolio-item {
        .portfolio-content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .single-footer-widget {
        h3 {
            font-size: 24px;
        }
    }
    .pr-5, .px-5 {
        padding-right: 0 !important;
    }
    
   
    .single-services-box {
        padding: 25px 20px 30px 20px;

        h3 {
            font-size: 22px;
        }
        .icon {
            i {
                height: 100px;
                width: 100px;
                line-height: 100px;
                font-size: 30px;
            }
        }
        &::before {
            bottom: -95%;
        }
    }
    .single-industries-box {
        h3 {
            font-size: 20px;
        }
    } 
    .single-team {
        .image {
            .content {
                padding: 20px 15px;
                margin: -10px auto 0;
            }
        }
    }
    .single-features {
        padding: 30px 10px;

        .icon {
            i {
                font-size: 35px;
            }
        }
        h3 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
    }

}

@media only screen and (min-width: 1200px) and (max-width: 2500px) {

    .container {
        max-width: 1280px;
    }

}

@media only screen and (min-width: 1550px) {
 
    
    .single-team {
        .image {
            .social {
                top: 50px;
            }
            .content {
                max-width: 350px;
            }
        }
    }
    

}