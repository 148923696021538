/* You can add global styles to this file, and also import other style files */

@import '~quill/dist/quill.snow.css';
/*================================================
Default CSS
=================================================*/
// body{
//     min-width: 280px !important;

// }

@font-face {
    font-family: "Somar";
    src: local("Somar"), url(./assets/fonts/Somar-Bold.ttf) format("truetype");
}



$main-font-family: somar,'Roboto', sans-serif;
$heading-font-family: somar,'DM Sans', sans-serif;
$main-color-asly: linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
$optional-color-asly: #f64c67;
$white-color-asly: #ffffff;
 $black-color-asly: #202647;
$paragraph-color: #6b6b84;
$others-color: #fd9b5f;

$optional-color: #c51f30;
$gold-color: #ca9703;
$white-color: #f4f2c9;
$black-color: #265999;
$ligthBlack-color: #4488bf;

$main-color:  radial-gradient(circle, #ca9703, #cdad45, #d2c173, #dad49f, #e5e5cb);
$gray-color:  #efeded;
$font-size: 16px;
$transition: .5s;
$transition2: .3s;

/* width */
::-webkit-scrollbar {
    width:5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

 
input
{
    color:#6b6b84;
}

 
 
 

.mousePointer{
    cursor: pointer;
}


.scrollMenu{
    height: 500px;
    overflow-y: scroll;
}


.required {
    color: $optional-color !important;
}
body {
    padding: 0;
    margin: 0;
    direction: rtl;
    text-align: right  !important;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
}
a {
    text-decoration: none;
    transition: $transition;
    color: $black-color;

    &:hover, &:focus {
        color: $main-color;
        text-decoration: none;
        
           
        
    }
}
button {
    outline: 0 !important;
}
p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: $paragraph-color;
    font-weight: 400;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
i {
    line-height: normal;
}
 
img {
    max-width: 100%;
    height: auto;
    display: inline-block;


}
.ptb-100 {
    padding: {
      // top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
 
.pb-70 {
    padding-bottom: 30px; 
} 
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    font-family: $heading-font-family;
    font-weight: bold;
    text-transform: capitalize;

    &:last-child {
        margin-bottom: 0;
    }
}

/*================================================
Default btn CSS
=================================================*/
.default-btn {
    display: inline-block;
    padding: 12px 35px;
    color: $white-color;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        // background: $main-color;asly
        background: linear-gradient(to right bottom, #265999, #2e66a3, #3873ac, #4380b5, #4f8dbe);;
        transition: $transition;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: $optional-color;asly
        background:linear-gradient(to left top, #265999, #2e66a3, #3873ac, #4380b5, #4f8dbe);

        transition: $transition;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    &:hover {
        color: $white-color;

        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

a {
     
    &:hover, &:focus, &.active {
        
        background-color:transparent !important ;
    }
}
/*================================================
Section Title CSS
=================================================*/
.section-title {
    text-align: center;
    margin-bottom: 45px;

    span {
        // color: $others-color;asly
        color: $gold-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h2 {
        font-size: 48px;
        margin: 14px 0 25px 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        // background: $main-color;asly
         background:  $gold-color;

        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin: auto;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
    &.text-width {
        max-width: 600px;
        margin: 0 auto 45px;
    }
}
@keyframes MOVE-BG { 
    0%{
    right:  0
}
  100%
  {  right: 85px}
  

}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $main-color;
    top: 0;
    left: 0;

    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
    }
    .box {
        width: 100%;
        height: 100%;
        background: $white-color;
        animation: animate .5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }
    .shadow {
        width: 100%;
        height: 5px;
        background: $optional-color;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        border-radius: 50%;
        animation: shadow .5s linear infinite;
    }
}
@keyframes loader {
    0% {
        left: -100px
    }
    100% {
        left: 110%;
    }
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1,.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes shadow {
    50% {
        transform: scale(1.2,1);
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.main-responsive-nav {
    display: none;
}
.main-navbar {
    padding-top: 0;

    .navbar {
        transition: $transition;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-right: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: auto;

            .nav-item {
                position: relative;
                margin: {
                    left: 10px;
                    right: 14px;
                };
                a {
                    color: $white-color;
                    //$black-color;
                    font: {
                        // size: $font-size;asly
                        size:15px;
                        weight: 700;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 25px;
                        bottom: 25px;
                    };
                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            right: -4px;
                            left: -3px;
                        };
                    }
                    &:hover, &:focus, &.active {
                        // color: $optional-color !important;asly
                        color: $gold-color !important;
                        background-color:transparent !important ;
                    }
                }
                &:last-child {
                    margin-left: 0;
                }
                &:first-child {
                    margin-right: 0;
                }
                &:hover, &.active {
                    a {
                        // color: $optional-color;asly
                        color: $gold-color;
                    }
                }
                .dropdown-menu {
                    border: none;
                    top: 60px;
                    // 70px;
                    left: auto;
                    z-index: 99;
                    right: 0;
                    text-align: right;
                    opacity: 0;
                    width: 200px;
                    display: block;
                    border-radius: 0;
                    padding: 10px 0;
                    margin-top: 15px;
                    position: absolute;
                    visibility: hidden;
                   // background:transparent;
                    // $white-color;
                    transition: all 0.4s ease-in-out;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    //kkkk
                        backdrop-filter: saturate(180%) blur(20px);
                        background-color: rgb(213 199 199 / 70%);
                        // rgb(208 184 184 / 20%);
                    li {
                        margin: 0;
                        border-bottom: 1px solid #f2efea;
                        a {
                            padding: 5px 20px;
                            position: relative;
                            display: block;
                            color: $white-color;
                            // $black-color;
                            cursor: pointer;
                            font: {
                                size: 16.5px;
                                weight: 700;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                left: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $optional-color;
                            }
                        }
                        .dropdown-menu {
                            top: 0;
                            opacity: 0;
                            left: -300px;
                            right: auto;
                            margin-top: 15px;
                            visibility: hidden;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover, &:focus, &.active {
                                        color: $optional-color;
                                    }
                                }
                                .dropdown-menu {
                                    top: 0;
                                    opacity: 0;
                                    right: 300px;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: $black-color;

                                            &:hover, &:focus, &.active {
                                                color: $optional-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            top: 0;
                                            opacity: 0;
                                            right: -300px;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: $black-color;

                                                    &:hover, &:focus, &.active {
                                                        color: $optional-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    top: 0;
                                                    opacity: 0;
                                                    right: 300px;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: $black-color;

                                                            &:hover, &:focus, &.active {
                                                                color: $optional-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            top: 0;
                                                            opacity: 0;
                                                            right: -300px;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: $black-color;

                                                                    &:hover, &:focus, &.active {
                                                                        color: $optional-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    right: 300px;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: $black-color;

                                                                            &:hover, &:focus, &.active {
                                                                                color: $optional-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $optional-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $optional-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $optional-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $optional-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $optional-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $optional-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-options {
            .option-item {
                margin-left: 20px;

                &:last-child {
                    margin-left: 0;
                }
                .cart-btn {
                    a {
                        font-size: 30px;
                        color: $black-color;
                        transition: $transition;
                        position: relative;

                        &:hover {
                            color: $optional-color;
                            transform: translateY(-5%);
                        }
                        span {
                            position: absolute;
                            left: 0;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            border-radius: 50%;
                            background: $optional-color;
                            color: $white-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                .search-btn {
                    font-size: 30px;
                    color: $white-color;
                    transition: $transition;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                }
                .close-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    display: none;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    padding: 10px 25px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}
.navbar-area {

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        right:75%;
        top: 100%;
        width: 300px;
        background:$gray-color; 
        z-index: 2;
        left: 12%;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 0;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    right: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                left: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover, &:focus {
                    color: $main-color;
                }
                i {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
}
.others-option-for-responsive {
    display: none;

    .dot-menu {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        z-index: 9991;
        position: absolute;
        left: 60px;
        top: -28px;

        .inner {
            display: flex;
            align-items: center;
            height: 50px;

            .circle {
                height: 5px;
                width: 5px;
                border-radius: 100%;
                margin: 0 2px;
                transition: $transition;
                background-color: $black-color;
            }
        }
        &:hover {
            .inner {
                .circle {
                    background-color: $main-color;
                }
            }
        }
    }
    .container {
        position: relative;

        .container {
            position: absolute;
            left: 0;
            top: 10px;
            max-width: 260px;
            background-color: $white-color;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
            margin-right: auto;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            transform: scaleX(0);
            z-index: 2;
            padding: {
                left: 15px;
                right: 15px;
            };
            &.active {
                opacity: 1;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
    .option-inner {
        .others-options {
            .option-item {
                margin-left: 20px;

                &:last-child {
                    margin-left: 0;
                }
                .cart-btn {
                    a {
                        font-size: 30px;
                        color: $black-color;
                        transition: $transition;
                        position: relative;

                        &:hover {
                            color: $optional-color;
                            transform: translateY(-5%);
                        }
                        span {
                            position: absolute;
                            left: 0;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            border-radius: 50%;
                            background: $optional-color;
                            color: $white-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                .search-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                }
                .close-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    display: none;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    padding: 10px 25px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {

    .main-responsive-nav {
        display: block;

        .main-responsive-menu {
            position: relative;

            &.mean-container {
                .mean-nav {
                    background-color: $black-color;
                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    // color:$optional-color;asly
                                    color: $gold-color;
                                }
                                i {
                                    display: none;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .others-options {
                    display: none !important;
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    // color:#f64c67;asly
                    color:#c51f30;
                    // $black-color;

                    span {
                    //  background: #f64c67;asly
                     background: #c51f30;
                    //   $black-color;
                        position: relative;
                    }
                }
            }
        }
        .logo {
            position: relative;

            img {
                max-width: 100px !important;
            }
        }
    }
    .navbar-area {
        // background-color: transparent;asly
        // $white-color;
        // backdrop-filter: saturate(180%) blur(20px);
    background-color: rgb(208 184 184 / 20%);
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .main-navbar {
        display: none;
    }
    .others-option-for-responsive {
        display: block;
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
 
.main-banner {
    overflow: hidden;
    position: relative;
}  
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes border-transform-default {
    0%, to {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }
}

   

/*================================================
Features Area CSS
=================================================*/
.single-features {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 35px 20px;
    position: relative;
    z-index: 1;
    transition: $transition;
    border-radius: 5px;
    margin: {
        bottom: 30px;
    };
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        border-radius: 5px;
    }
    .icon {
        margin-bottom: 16px;

        i {
            font-size: 50px;
            color: $optional-color;
            transition: $transition;
            line-height: 1;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
        transition: $transition;

        a {
            color: $black-color;
        }
    }
    p {
        transition: $transition;
        margin-bottom: 0;
    }
    .number {
        position: absolute;
        bottom: 10px;
        left: 10px;

        span {
            font-size: 25px;
            font-weight: 500;
            color: #cbcac9;
            transition: $transition;
        }
    }
    .shape {
        position: absolute;
        bottom: 25px;
        right: 18px;
        z-index: -1;
        transition: $transition;
        opacity: 30%;
    }
    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
        }
        .icon {
            i {
                color: $white-color;
            }
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .number {
            span {
                color: $white-color;
            }
        }
        .shape {
            opacity: 30%;
            bottom: 35px;
        }
    }
}
.single-features-box {
    background-color: $gray-color; 
    box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px; 
    padding: 35px 20px;
    position: relative;
    z-index: 1;
    transition: $transition;
    border-radius: 5px;
    text-align: center;
       border-top: 3px solid $gold-color;
    border-bottom: 3px solid $gold-color;
    min-height: 286px;
    height: 286px;
    margin: {
        bottom: 30px;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        background: $main-color;
        z-index: -1;
        transition: $transition;
        border-radius: 5px;
    }
    .icon {
        margin-bottom: 16px;

        i {
            font-size: 30px;
            color: $gold-color;
            transition: $transition;
            line-height: 1;
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            border-radius: 50px;
             background-color: $white-color-asly;
             //#f5f5f5; asly
        }
    }
    h3 {
        // font-size: 22px;asly
        font-size: 20px;
        line-height: 1.4;
        font-weight: 600;
        margin-bottom: 12px;
        transition: $transition;

        a {
            color: $black-color;
            height: auto;
            overflow: hidden;
            display: block;
            max-height: 56px; 
            // min-height: 56px;
        }
    }
    p {
        transition: $transition;
        margin-bottom: 0;
        overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: large;
    font-weight: 500;
    }
    &:hover {
        &::before {
            height: 100%;
        }
        .icon {
            i {
                color: $gold-color;
            }
        }
        h3 {
            a {
                color: $white-color-asly;
            }
        }
        p {
            color: $white-color-asly
        }
    }
    &.top-1 {
        margin-top: 30px;
    }
    &.top-2 {
        margin-top: 0;
    }
    &.top-3 {
        margin-top: 0;
    }
    &.top-4 {
        margin-top: -30px;
    }
} 
 
/*================================================
Services Area CSS
=================================================*/
.services-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-fff5ee {
        background-color: #fff5ee;
    }
    &.bg-fafafa {
        background-color: #fafafa;
    }
    &.bg-fafafa {
        background-color: #fafafa;
    }
    &.bg-0f051e {
        background-color: #0f051e;

        .section-title {
            h2 {
                color: $white-color;
            }
        }
    }
}
.single-services {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    margin-bottom: 30px;
    text-align: center;
    padding: 35px 30px 75px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        bottom: -88%;
        right: 0;
        width: 120%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: $main-color;
        transform: rotate(14deg);
    }
    .image {
        margin-bottom: 25px;
    }
    h3 {
        font-size: 25px;
        margin-bottom: 16px;

        a {
            color: $black-color;
        }
    }
    .services-btn {
        color: $optional-color;
        font-weight: 500;
        transition: $transition;

        i {
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .services-btn {
            letter-spacing: 1px;
        }
        h3 {
            a {
                color: $optional-color;
            }
        }
    }
    .icon {
        margin-bottom: 20px;

        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background: $main-color;
            color: $white-color;
            font-size: 45px;
            border-radius: 50px;
        }
    }
}
.single-services-item {
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;
.image{
   img{
        vertical-align: middle;
    border-style: none;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
   }
    a{
            overflow: hidden;
            height: 200px;
            display: block;
        // box-shadow: 10px 10px 5px #c8c4ca;asly
        box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
    }
}
    .content {
        // background-color: $white-color;asly
        // background-color: $white-color-asly;asly
        // box-shadow: 10px 10px 5px rgb(24 28 33 / 10%);asly
        box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
        background-color: $gray-color;
        padding: 17px 20px;
        max-width: 325px;
        margin: -50px auto 0;
        position: relative;
        z-index: 1;
        transition: $transition;
        border-radius: 5px;
            height: 118px;
            overflow: hidden;

        h3 {
            margin-bottom: 5px;
            font-size: 20px;
            transition: $transition;
            line-height: 1.4;
            a {
                color: $black-color;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 100%;
            }
        }
        span {
            font-weight: bold;
            transition: $transition;
            color: $paragraph-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        display: inline-block;
        }
        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 0;
            background: $main-color;
            z-index: -1;
            transition: $transition;
            border-radius: 5px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    // color: $white-color;asly
                     color: $white-color-asly;
                }
            }
            span {
                     // color: $white-color;asly
                     color: $white-color-asly;
            }
        }
    }
}
.single-services-box { 
   background-color:$gray-color; 
    box-shadow: 10px 10px 5px #c8c4ca;
    margin-bottom: 30px;
    text-align: center;
    padding: 35px 30px 45px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;
    border-top: 3px solid $gold-color;
    margin-top: 4%;//asly
    &::before {
        content: '';
        position: absolute;
        bottom: -92%;
        right: 0;
        width: 120%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: linear-gradient(to right top, #d5ad36, #dcc163, #e3d48d, #ede7b7, #f9f8e1);//asly
        transform: rotate(7deg);
    }
    .icon {
        margin-bottom: 20px;
        transition: $transition;

        i {
            display: inline-block;
            height: 120px;
            width: 120px;
            line-height: 120px;
            background: $main-color;
            color: $white-color-asly;//asly
            font-size: 45px;
            border-radius: 50px;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
    }
    h3 {
        font-size: 20px;
        margin-bottom: 16px;
        line-height: 1.4;
        a {
            color: $black-color;
            height: auto;
            overflow: hidden;
            display: block;
            max-height: 62px;
        }
    }
    p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: large;
        font-weight: 500;
    }
    .services-btn {
        color: $optional-color;
        font-weight: 500;
        transition: $transition;

        i {
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .services-btn {
            letter-spacing: 1px;
        }
        h3 {
            a {
                // color: $optional-color;asly
                color: $gold-color;
            }
        }
        .icon {
            animation: bounce 2s
        }
    }
}  
@media (min-width: 992px){
    .offset-lg-6 {
        margin-right: 50%;
        margin-left: 0;
    }
}
.mean-container .mean-bar {
    float: right;
}
.mean-container a.meanmenu-reveal {
    right: auto !important;
    left: 0 !important;
}
.mean-container .mean-nav {
    float: right;
}
.mean-container .mean-nav ul li {
    float: right;
}
.mean-container .mean-nav ul li a {
    float: right;
    text-align: right;
}
.mean-container .mean-nav ul li a.mean-expand {
    right: auto;
    left: 0;
    text-align: left;
}

 

 

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-fff5ee {
        background-color: #fff5ee;
    }
    &.bg-f1f1f1 {
        background-color: #f1f1f1;
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}
.single-portfolio {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    .portfolio-content {
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-55%);
        text-align: center;
        right: 0;
        margin-top: 0;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        i {
            display: inline-block;
            height: 35px;
            width: 35px;
            line-height: 38px;
            background-color: $white-color;
            color: $optional-color;
            border-radius: 50px;
            font-size: 22px;
            transition: $transition;

            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
        h3 {
            font-size: 25px;
            transition: $transition;
            margin-top: 12px;
            margin-bottom: 0;

            a {
                color: $white-color;
            }
        }
    }
    &:hover, &:focus {
        transform: translateY(-5px);

        &::before {
            opacity: 0.9;
            visibility: visible;
        }
        .portfolio-content {
            opacity: 1;
            visibility: visible;
            margin-top: -15px;
        }
    }
}
.single-portfolio-item {
    margin-bottom: 30px;
    text-align: center;
    transition: $transition; 
    background-color: $gray-color; 
    box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
    border-radius: 10px 10px 0 0;

    .portfolio-image {
        height: 200px;
        a{
            display: block;
            height: 200px;
            overflow: hidden;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: $transition;
            border-radius: 10px 10px 0 0;
        }
    }


    .portfolio-content {
        padding: 10px 20px;
        position: relative;
        z-index: 1;
        transition: $transition;

        h3 {
            margin-bottom: 10px;
            font-size: 20px;
            transition: $transition;
            line-height: 1.4;
            a {
                color: $black-color;
                font-weight: bold;
                white-space: nowrap;
                  text-overflow: ellipsis;


                overflow: hidden;
                display: block;
                font-size: 20px;
            }
        }
        span {
            font-weight: bold;
            transition: $transition;
            // color: $paragraph-color;asly
            color:$gold-color;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            background: $main-color;
            z-index: -1;
            transition: $transition;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .portfolio-content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    // color: $white-color;asly
                    color: $white-color-asly
                }
            }
            span {
                // color: $white-color !important;asly
                color: $white-color-asly !important;
            }
        }
    }
} 
 
/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-blog {
    overflow: hidden;
    // background-color: $white-color;asly
    // box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    // box-shadow: 10px 10px 5px #c8c4ca; asly
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // #cccaca;;
    margin-bottom: 30px;

    .image {
        height: 200px;
        a{
            display: block;
            height: 235px;
            overflow: hidden;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: $transition;
            border-radius: 10px 10px 0 0;
        }
    }
    .content {
        padding: 5px 20px;
        // background-color: $white-color-asly;//asly
    background-color: $gray-color;

        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        position: relative;
        z-index: 9;
text-align: right;
        span {
            //color: $optional-color; asly
            color: $gold-color;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    }
        h3 {
            font-size: 25px;
            line-height: 1.5;
            margin-top: 1px;
            margin-bottom: 2px;
            transition: $transition;

            a {
                color: $black-color;
    height: 65px;
    overflow: hidden;
    display: block;
    font-size: 20px;
            }
        }
        .blog-btn {
            font-weight: 600;
            color: $optional-color;
            transition: $transition;
            margin-right: 11px;
            i {
                position: relative;
                top: 2px;
            }
        }
    }
    &:hover {
        .content {
            .blog-btn {
                letter-spacing: 1px;
            }
        }
        .image {
            img {
                transform: rotate(8deg) scale(1.3);
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
 
.single-team {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    .image {
        position: relative;
        transition: $transition;

        img {
            border-radius: 10px;
        }
        .social {
            position: absolute;
            top: 20px;
            left: 20px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                transform: perspective(1000px) rotateY(90deg);
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: $white-color;
                    box-shadow: 1px 20px 30px rgba(0, 0, 0, 0.07);
                    text-align: center;
                    position: relative;
                    font-size: 22px;
                    color: $main-color;
                    border-radius: 30px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background: $optional-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
                &:nth-child(3) {
                    transition-delay: 0.4s;
                }
                &:nth-child(4) {
                    transition-delay: 0.5s;
                }
            }
        }
        .content {
            background-color: $white-color;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            padding: 35px 20px;
            max-width: 265px;
            margin: -50px auto 0;
            position: relative;
            z-index: 1;
            transition: $transition;
            border-radius: 5px;

            h3 {
                margin-bottom: 10px;
                font-size: 25px;
                transition: $transition;

                a {
                    color: $black-color;
                }
            }
            span {
                font-weight: bold;
                transition: $transition;
                color: $paragraph-color;
            }
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 0;
                background: $main-color;
                z-index: -1;
                transition: $transition;
                border-radius: 5px;
            }
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
            .content {
                &::before {
                    height: 100%;
                }
                h3 {
                    color: $white-color;
                }
                span {
                    color: $white-color;
                }
            }
        }
    }
}
.team-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid #b7bcbf;
                    //1px solid $optional-color;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: $main-color;
                        border-radius: 50%;
                        margin: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                        background: $gold-color !important;

                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                            background: $gold-color !important;
                        }
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 10px;
            line-height: 0;
        }
    }
}
.single-team-box {
    margin-bottom: 30px;
    text-align: center;

    .image {
        position: relative;
        transition: $transition;
        overflow: hidden;

        .social {
            padding-right: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            };
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 1px;
                    right: 1px;
                };
                a {
                    display: block;
                    width: 33px;
                    height: 33px;
                    background-color: #f4f5fe;
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: $black-color;
                    border-radius: 50px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                &:nth-child(4) {
                    transition-delay: 0.4s;
                }
            }
        }
        img {
            border-radius: 5px;
            transition: $transition;
        }
    }
    .content {
        margin-top: 20px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        span {
            display: block;
            color: $optional-color;
            font-size: 14px;
            margin-top: 6px;
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
            img {
                transform: rotate(-5deg) scale(1.1);
            }
        }
    }
}

 

/*================================================
Industries Area CSS
=================================================*/
.industries-area {
    background: linear-gradient(to right top, #ffbac3, #ffc4c3, #ffcec5, #ffd7ca, #fee1d2);
}
.single-industries-box {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 0 50px 0 50px;
    background-color: #f7f9fc;
    overflow: hidden;
    transition: $transition;
    padding: {
        right: 25px;
        left: 48px;
        top: 35px;
        bottom: 35px;
    }
    &::before {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        // background: $main-color;asly
        background: linear-gradient(to right top, #d5ad36, #dcc163, #e3d48d, #ede7b7, #f9f8e1);
        z-index: -1;
        content: '';
        transition: $transition;
    }
    i {
        position: absolute;
        left: 10px;
        top: 50%;
        transition: $transition;
        // color: #ffa6b3;asly
        color: $gold-color;
        transform: translateY(-50%);
        font-size: 50px;

        &::before {
            transition: $transition;
            font-size: 50px;
        }
    }
    h3 {
        margin-bottom: 0;
        transition: $transition;
        font: {
            size: 18px;
            weight: 700;
        }
    }
    a {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
    &:hover {
        padding-right: 74px;

        &::before {
            width: 100%;
        }
        i {
            right: 25px;
            // color: $white-color;asly
            color: $gold-color;
            font-size: 35px;

            &::before {
                font-size: 35px;
            }
        }
        h3 {
                        // color: $white-color;asly
                        color: $black-color;
        }
    }
}
 
 

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
    background-color: #fafafa;
    position: relative;
    z-index: 1;
    padding-top: 100px;
}
.overview-content {
    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        // font-size: 48px;asly
        font-size: 38px;
        margin-top: 12px;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 0;
    }
    .overview-btn {
        margin-top: 30px;
    }
}
.overview-image {
    img {
        border-radius: 100%;
        animation: border-transform-default 10s linear infinite alternate forwards;
    }
}

/*================================================
Page Banner CSS
=================================================*/
.page-banner-area {
    position: relative;
    z-index: 1;
    background: { 
        position: center center;
        size: cover;
        repeat: no-repeat; 
        attachment: inherit;
    };
    padding: {
        top: 140px;
        bottom: 140px;
    };
    
    &.item-bgNews{
        // background-image: url(assets/img/page-banner/page-banner-2.jpg);
        
       background-image: url('assets/img/News.avif');
    }
    &.item-bgMain {
        background-image: url(assets/img/Main.avif);
    } 
    &.item-bgMethods{ 
       background-image: url('assets/img/Methods.avif');
       &::before{
        background-color:
        #00000073 !important ;
        //; #00000069
    }
    }
    &.item-bgLessons {
        background-image: url(assets/img/Lessons.avif);
    } 
    &.item-bgCurriculars{
       background-image: url('assets/img/Curriculars.avif');
       &::before{
        background-color:#000000ab !important ;
    }
    }
    &.item-bgContact {
        background-image: url(assets/img/Contact.avif);
        &::before
        {
            background-color: #00000091  !important;
        }
    } 
    &.item-bgSites{ 
       background-image: url('assets/img/Sites.avif');
    }
    &.item-bgLibrary{
        background-image: url(assets/img/Library.avif);
        &::before {
            background-color:  #000000ab   !important;
        }    
    } 
 
    &.item-bgEvents{ 
       background-image: url('assets/img/Events.avif');
       &::before{
           background-color:#000000ad !important ;
       }
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #0000005c;
        //#00000075;
        // #000000;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        opacity: .60;
    } 
}
.page-banner-content {
    text-align: center;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        margin-bottom: 20px;
        font-size: 45px;
    }
    ul {
        padding-right: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            font-weight: 600;
            // color: $optional-color;asly
            color: $gold-color;
            position: relative;
            margin: {
                left: 10px;
                right: 10px;
            };
            &::before {
                content: '';
                position: absolute;
                right: -10px;
                top: 7px;
                height: 12px;
                width: 1px;
                // background: $main-color;//asly
                background: $white-color-asly;
            }
            &:first-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &:last-child {
                margin-left: 0;
            }
            a {
                display: block;
                color: $white-color;
            }
        }
    }
}
 
/*================================================
Login Area CSS
=================================================*/
.login-form {
    padding: 40px 50px;
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 0 10px rgba(51,51,51,.1);

    h2 {
        margin-bottom: 30px;
        font: {
            size: 35px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: $paragraph-color;
                font-weight: 400;
                font-size: $font-size;
            }
            .form-control {
                height: 55px;
                padding: 0 15px 0 0;
                line-height: initial;
                color: $black-color;
                background-color: transparent !important;
                border: 1px solid #b5b1b1;
                border-radius: 5px;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 400;
                };
                &:focus {
                    border-color: $optional-color;
                }
            }
        }
        .lost-your-password {
            text-align: left;

            a {
                display: inline-block;
                position: relative;
                font-weight: 500;
                font-size: 15px;
                color: $paragraph-color;

                &::before {
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    content: '';
                    transition: $transition;
                    background-color: #eeeeee;
                }
                &::after {
                    width: 0;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    transition: $transition;
                    bottom: 0;
                    content: '';
                    background-color: $optional-color;
                }
                &:hover {
                    &::before {
                        width: 0;
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
        .form-check {
            font-weight: 500;
            font-size: 15px;
            color: $paragraph-color;
        }
        .form-check-input {
            position: absolute;
            margin-top: 4px;
            margin-right: -18px;
        }
        button {
            position: relative;
            z-index: 1;
            border: none;
            margin-top: 25px;
            padding: 12px 30px;
            width: 100%;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}
 

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    border-top: 1px solid #eeeeee;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 750px;

    h3 {
        font-size: 38px;
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 25px;
    }
}
 
 

/*================================================
Contact Info Area CSS
=================================================*/
.contact-info-box {
    text-align: center;
    border-radius: 5px;
    transition: $transition;
   // box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 10px 10px 5px #c8c4ca;
    background: $gray-color;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background: radial-gradient(circle, #ca9703, #cdad45, #d2c173, #d7cf8b, #e3e3b0);
        // $main-color;
        z-index: -1;
        transition: $transition;
        border-radius: 5px;
    }
    .icon {
        display: inline-block;
        width: 70px;
        height: 70px;
        line-height: 70px;
        // background: #f5f5f5;asly
        background: $white-color-asly;
        border-radius: 50%;
        font-size: 35px;
        color: $optional-color;
        transition: $transition;
        margin-bottom: 12px;
        position: relative;

        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }
    h3 {
        margin-bottom: 12px;
        transition: $transition;
        font: {
            size: 20px;
            weight: 700;
        };
    }
    p {
        margin-bottom: 2px;
        transition: $transition;

        overflow: hidden;
        display: block;
        font-size: 20px;
        a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover {
        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color-asly;
        }
        p {
            color: $white-color-asly;

        }
        p {
            a {
                color: $white-color-asly;
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/

.contact-form {
    padding: 30px 25px;
    border-radius: 10px;
    background-color: $white-color-asly;
    box-shadow: 0 0 10px rgba(51,51,51,.1);
    max-width: 900px;
    margin: auto;

    .form-group {
        .form-control {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            background-color: $white-color-asly;
            border: 1px solid #c9c6c6;
            border-radius: 3px;
            box-shadow: none;
            padding: 0 12px;
            transition: $transition;

            &:focus {
                outline: 0;
                background-color: $white-color-asly;
                border-color: $optional-color;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding: 8px 12px;
            line-height: 1.5rem;
        }
        label {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
            color: $black-color;//asly
        }
    }
    #msgSubmit {
        margin: 0;
        font-size: 1.3rem;

        &.text-danger, &.text-success {
            margin-top: 15px;
            font-size: 20px;
        }
    }
    .default-btn {
        position: relative;
        z-index: 1;
        border: none;
        margin-top: 10px;
    }
}
.contact-form-inner {
    .form-group {
        .form-control {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            background-color: $white-color;
            border: 1px solid #f1f1f1;
            border-radius: 3px;
            box-shadow: none;
            padding: 0 12px;
            transition: $transition;
            font-size: 15px;

            &:focus {
                outline: 0;
                background-color: $white-color;
                border-color: $optional-color;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding: 8px 12px;
            line-height: 1.5rem;
        }
    }
    .default-btn {
        position: relative;
        z-index: 1;
        border: none;
        margin-top: 10px;
    }
} 

/*================================================
Services Details Area CSS
=================================================*/
.services-details-desc {
    h3 {
        margin-top: 30px;
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
    }
    p {
        margin-bottom: 0;
    }
    
    .text-result {
        h3 {
            margin-top: 0;
            margin-bottom: 12px;
            font: {
                size: 25px;
            };
        }
        p {
            margin-bottom: 0;
        }
    }
    .services-details-faq {
        margin-top: 30px;

        .accordion {
            list-style-type: none;
            padding-right: 0;
            margin: {
                bottom: 0;
            };
            .accordion-item {
                display: block;
                background: #f3f3f3;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .accordion-title {
                padding: 25px 20px 20px 40px;
                color: $black-color;
                position: relative;
                display: block;
                text-transform: capitalize;
                font: {
                    size: 18px;
                    weight: 600;
                };
                i {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $black-color;
                    font-size: 20px;
                    transition: $transition;
                }
                &.active {
                    i {
                        &::before {
                            content: "\eace";
                            color: $optional-color;
                        }
                    }
                }
            }
            .accordion-content {
                display: none;
                position: relative;
                padding: 18px 20px;
                border-top: 1px solid $optional-color;
                color: $paragraph-color;

                &.show {
                    display: block;
                }
            }
        }
    }
} 
 

/*================================================
Portfolio Details Area CSS
=================================================*/
  
.portfolio-details-information {
    // background-color: $white-color;
    // box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
    background-color: $gray-color;
    box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
    border-radius: 5px;
    border-top: 3px solid $optional-color;
    padding: 40px 30px;

    ul {
        padding-right: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 20px;
            border-bottom: 1px solid #d7d4d49e;// #eeeeee;asly
            position: relative;
            color: $paragraph-color;
            font-weight: 600;
            font-size: 15px;
            padding-right: 35px;
            padding-bottom: 20px;

            span {
                color: $black-color;
                display: block;
                margin-bottom: 8px;
                font-size: 20px;
                font-weight: 600;
            }
            a {
                display: block;
                color: $paragraph-color;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    color: $optional-color;
                }
            }
            .icon {
                position: absolute;
                right: 0;
                top: 0;
                color: $optional-color;
                font-size: 25px;
            }
            &:hover 
            {
               .icon{ 
                   animation:tada 1.5s ease infinite;
                }
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        // margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;

            ul {
                padding-right: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-left: 20px;

                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-left: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        left: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-left: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            font-size: 25px;
            margin: {
                bottom: 15px;
                top: 25px;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-right: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-block-start: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-right: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 40px;
                    background-color: #faf5f5;
                    color: $optional-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    right: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $optional-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;

            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-left: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                margin-left: 2px;
                font-weight: 500;
                color: $paragraph-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;

            .social {
                padding-right: 0;
                list-style-type: none;
                text-align: left;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-left: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $optional-color;
                        width: 30px;
                        height: 30px;
                        line-height: 34px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;

                        &:hover {
                            color: $white-color;
                            background-color: $optional-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
}
blockquote, .blockquote {
    background-color: #fafafa;
    padding: 30px !important;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: 3px solid $optional-color;
    border-right: 3px solid $optional-color;
    border-radius: 5px;

    p {
        color: $black-color;
        margin-bottom: 0;
        font-size: 18px !important;
        font-weight: 500;
        font-style: italic;
    }
}  

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
    // 0px 9px 25px rgb(0 0 0 / 10%);asly
    border-radius: 5px;
    // border-top: 3px solid #f64c67;asly
    border-top: 3px solid $black-color;
    background-color: $gray-color;
    
    .widget {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 10px;
            border-bottom: 1px solid #eeeeee;
            font-size: 20px;
            color: $black-color !important;//asly

            &::before {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                // background: $main-color;asly
                background: $black-color;

            }
        }
    }
    .widget_search {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: $white-color-asly;
                //transparent;asly
                height: 50px;
                padding: 8px 15px;
                border: 1px solid #c9c4c4; //#eeeeee;asly
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                border-radius: 5px;
                box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
                &:focus {
                    border-color:$black-color;
                    // $optional-color;asly
                }
            }
            button {
                border: none;
                //background-color: #eeeeee;
                // color: $optional-color;asly
                height: 40px;
                // width: 40px;asly
                // position: absolute;asly
                left: 5px;
                padding: 0;
                transition: $transition;
                top: 5px;
                font-size: 20px;
                border-radius: 5px;
                cursor: pointer;
              width: 100%;
              background:
              linear-gradient(to right bottom, #265999, #2e66a3, #3873ac, #4380b5, #4f8dbe);
             // linear-gradient(to right bottom, #c51f30, #cc2837, #d32f3f, #d93746, #e03e4e);// red
              color: white; 
              position: relative;



                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 52%;
                    transform: translateY(-52%);
                }
                &:hover, &:focus {
                    // background-color: $optional-color;asly
              background: 
              //$ligthBlack-color;
              linear-gradient(to left top, #265999, #2e66a3, #3873ac, #4380b5, #4f8dbe);
            
            // linear-gradient(to left top, #c51f30, #cc2938, #d33241, #d93a49, #e04252);//red
                  color: $white-color;
                }
            }
        }
    }
    .widget_rainz_posts_thumb {
        position: relative;
        overflow: hidden;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: right;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-left: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    margin-top: -2px;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font-size: 17px;
                    font-weight: bold;

                    a {
                        display: inline-block;
                        transition: $transition;

                        &:hover {
                            color: $optional-color;
                        }
                    }
                }
            }
        }
    }
    .widget_categories {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        ul {
            padding-right: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: $black-color;
                padding-right: 18px;
                font-size: 15px;
                font-weight: 500;

                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 8px;
                    width: 8px;
                    content: '';
                    border-radius: 50%;
                    right: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: $optional-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .widget-title {
            margin-bottom: 18px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background: #f5f5f5;
            color: $black-color;
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font-weight: 500;
            font-size: 15px !important;
            margin-top: 8px;
            margin-left: 5px;

            &:hover, &:focus {
                color: $white-color;
                background-color: $optional-color;
            }
        }
    }
    .price_list_widget {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .form-group {
            .form-control {
                display: block;
                width: 100%;
                height: 50px;
                outline: 0;
                background-color: $white-color;
                border: 1px solid #f1f1f1;
                border-radius: 3px;
                box-shadow: none;
                padding: 0 12px;
                transition: $transition;

                &:focus {
                    outline: 0;
                    background-color: $white-color;
                    border-color: $optional-color;
                    box-shadow: none;
                }
            }
        }
    }
    .widget_popular_products {
        position: relative;
        overflow: hidden;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .widget-title {
            margin-bottom: 30px;
        }
        .item {
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: right;
                height: 110px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 110px;
                margin-left: 15px;
                z-index: 1;

                .fullimage {
                    width: 110px;
                    height: 110px;
                    display: inline-block;
                    border-radius: 2px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    // &.bg1 {
                    //     background-image: url(assets/img/shop/shop1.jpg);
                    // }
                    // &.bg2 {
                    //     background-image: url(assets/img/shop/shop2.jpg);
                    // }
                    // &.bg3 {
                    //     background-image: url(assets/img/shop/shop3.jpg);
                    // }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    text-transform: uppercase;
                    margin-top: -5px;
                    margin-bottom: 6px;
                    font-weight: 500;
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font-weight: 500;
                    font-size: 18px;

                    a {
                        display: inline-block;
                        &:hover {
                            color: $optional-color;
                        }
                    }
                }
                .rating {
                    margin-top: 8px;

                    i {
                        color: #f6b500;
                        display: inline-block;
                        margin-left: -2px;
                    }
                }
            }
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.rainz-grid-sorting {
    margin-bottom: 40px;

    .result-count {
        p {
            .count {
                font-weight: 700;
                color: $black-color;
            }
        }
    }
    .ordering {
        text-align: left;

        label {
            display: inline-block;
            margin-bottom: 0;
            color: $black-color;
            margin-left: 5px;
            font-weight: 600;
            margin-top: 1%;//asly
        }
        .nice-select {
            display: inline-block;
            float: unset;
            width: 100%;
            //kk
            // background: #f8f8f8;
            background: white;
            border-color: #eeeeee;
            color: #6b6b84;
            // $black-color;asly
            transition: $transition;
            padding: 0 12px 0 0;
            height: 45px;
            line-height: 45px;
            font-size: 15px;
            font-weight: 500;
            text-align: right !important;
            box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px;
            .list {
                background-color: #efeded;
                border-radius: 5px;
                box-shadow: 0 10px 30px rgba(0,0,0,.2);
                list-style-type: none;
                border: none;
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                padding-right: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: right !important;
                overflow-y: auto;
                max-height: 200px;
                .option {
                    line-height: 38px;
                    min-height: 38px;
                    color: $black-color;
                    position: relative;
                    transition: $transition;
                    padding-left: 15px;
                    padding-right: 25px;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: right !important;
                     white-space: normal;
                    &:hover {
                        background-color: $black-color !important;
                        color: $white-color;
                    }
                }
            }
            &:after {
                border-color: $black-color;
                height: 8px;
                width: 8px;
                margin-top: -5px;
                left: 12px;
                right: auto;
            }
            &:hover {
                border-color: $black-color;
                background-color: transparent;
            }
        }
    }
} 
 


 

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: #f5f3f3;
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 25px;
    }
    p {
        color: #666666;
        margin: {
            bottom: 0;
        };
    }
    .social {
        padding-right: 0;
        list-style-type: none;
        margin-top: 25px;
        margin-bottom: 0;
        width: 70%;
        margin: auto;
        li {
            display: inline-block;
            margin-left: 10px;

            &:last-child {
                margin-left: 0;
            }
            a {
                display: block;
                width: 35px;
                height: 35px;
                line-height: 40px;
                border-radius: 50%;
                background-color: $main-color;
                color: $white-color;
                border: 1px solid $main-color;
                text-align: center;
                font-size: 18px;

                &:hover, &:focus {
                    color: $main-color;
                    background-color: $optional-color;
                }
                &.facebook {
                    background-color: #3b5998;
                    border-color: #3b5998;
                    color: $white-color;
                    &:hover, &:focus, &.active {
        
                        background-color:#3b5998 !important ;
                    }
                    // &:hover, &:focus {
                    //    // color: $white-color;
                    //     //background-color: $optional-color;
                    // }
                }
                &.twitter {
                    background-color: #1da1f2;
                    border-color: #1da1f2;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &.linkedin {
                    background-color: #007bb5;
                    border-color: #007bb5;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &.youtube {
                    background-color: #ee1919;
                    border-color: #c13584;
                    color: $white-color;
                    &:hover, &:focus, &.active {
        
                        background-color:#ee1919 !important ;
                    }
                    // &:hover, &:focus {
                    //     color: $white-color;
                    //     //background-color: $optional-color;
                    // }
                }
            }
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 15px;
        // color: $optional-color;asly
         color: $gold-color;
    }
    .quick-links {
        padding-right: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 10px;
            font-size: 15px;

            a {
                display: inline-block;
                color: $black-color;
                font-weight: 500;
                position: relative;

                &:hover {
                    // color: $optional-color;
                    color: $gold-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-contact-info {
        padding-right: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 22px;
            color: $black-color;
            position: relative;
            padding-right: 35px;

            i {
                position: absolute;
                right: 0;
                top: 2px;
                font-size: 22px;
                 color: $optional-color;

            }
            span {
                display: block;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 5px;
                color: $black-color;
            }
            a {
                //display: inline-block;
                // color: $black-color; asly
                  color: #4488bf;
                font-weight: 400;
                position: relative;
                font-size: 15px;
                word-wrap: break-word;
    //             font-size: 13px;
    // word-wrap: normal;
                &:hover {
                    // color: $optional-color;asly
                    color: $gold-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Copy Right Area CSS
=================================================*/
.copyright-area {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #0f171f;

    .copyright-area-content {
        p {
            color: $white-color;

            a {
                display: inline-block;
                font-weight: 600;
                color: $optional-color;

                &:hover {
                    color: $white-color;
                }
            }
        }
        ul {
            padding-right: 0;
            margin-bottom: 0;
            list-style-type: none;
            text-align: left;
            margin-left: 25px;
            li {
                display: inline-block;
                position: relative;
                margin: {
                    left: 10px;
                    right: 10px;
                };
                a {
                    display: inline-block;
                    color: $white-color;

                    &:hover {
                        color: $gold-color;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: -10px;
                    width: 1px;
                    height: 14px;
                    background: $main-color;
                }
                &:last-child {
                    margin-left: 0;

                    &::before {
                        display: none;
                    }
                }
                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    left: 20px;
    color: $white-color;
    background-color: $black-color;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 25px;
    transition: .9s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 30px;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 20px;
    }
    &:hover {
        background: $optional-color;
        color: $white-color;
        transition: $transition;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }
}
html, body { height: 100%; }
body { margin: 0; font-family:somar, Roboto, "Helvetica Neue", sans-serif; }//asly








  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    text-align: center;
flex-wrap: wrap;
    margin-top: 20px;
justify-content: center;
  }
.page-link:hover {
    background: $optional-color !important;
    color: $white-color;
    box-shadow: 0 2px 10px 0 #d8dde6;
    
}
.page-link {
    width: 35px;
    height: 35px;
    margin: 0 3px;
    display: inline-block;
    background-color: #ffffff;
    line-height: 36px;
    color: #202647;
    box-shadow: 0 2px 10px 0 #d8dde6;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    padding: 0 0;
    text-align: center;
    margin-top: 20px;
}
.page-item.active .page-link {
    // background: #f64c67;asly
    background:$optional-color  !important;
    color: #ffffff;
    box-shadow: 0 2px 10px 0 #d8dde6;
    border-color: #d8dde6;
}
.sr-only {
    width: 35px;
    height: 35px;
    margin: 0 3px;
    display: inline-block;
    background-color: #ffffff;
    line-height: 36px;
    color: #202647;
    box-shadow: 0 2px 10px 0 #d8dde6;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-radius: 50px  !important;

    span {
        line-height: 10px;
        font-size: x-large;
        margin: -9px;

    }
}
.page-item:last-child .page-link {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-radius: 50px  !important;

        span {
            line-height: 10px;
            font-size: x-large;
        margin: -9px;

        }
}
.page-item:nth-child(2) .page-link {

        span {
            line-height: 10px;
            font-size: x-large;
       

        }
}
.page-item:nth-last-child(2) .page-link {

    span {
        line-height: 10px;
        font-size: x-large;
    }
}

.ngx-dropdown-container{

  
    .available-items{
        padding-right: 0%  !important;
    }

    .ngx-dropdown-button{
        display: inline-block !important;
        float: unset !important;
        width: 100% !important;
        background: white !important;
        border-color: #eeeeee !important;
        color: #6b6b84 !important;
        transition: 0.5s !important;
        padding: 0 12px 0 0 !important;
        
        min-height: 50px  !important;
        line-height: 45px !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        text-align: right !important;
        box-shadow: rgb(0 0 0 / 15%) 2px 1px 3px 0px !important;
        &:hover {
            border-color: #265999 !important;
            border-style: solid !important;
            border-width: .5px !important;
        }
    .display-text{
        text-align: right  !important;
    }
    .nsdicon-angle-down{
        float: left !important;
        right: 0px !important;
    }
    .nsdicon-angle-down::before{
        left: 100%  !important;
        border-color: #265999  !important;
    }
}
 .ngx-dropdown-list-container{
    background-color: #efeded !important;
    border-radius: 5px !important;
    box-shadow: 0 10px 30px rgb(0 0 0 / 20%) !important;
    list-style-type: none !important;
    border: none !important;
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: right !important;
    overflow-y: auto !important;
    max-height: 200px !important;
    ul{
        padding-right: 2% !important;
    
    li{
        line-height: 38px !important;
    min-height: 38px !important;
    color: #265999 !important;
    position: relative !important;
    transition: 0.5s !important;
    padding-left: 15px !important;
    padding-right: 25px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: right !important;
    white-space: normal !important;
     &:hover {
    background-color: #265999 !important;
    color: $white-color  !important;
    }
    }
}
 }

 .selected-items {
    li{
        background-color: #f6f6f6 !important;
    &:hover {
    background-color: #265999 !important;
    color: $white-color  !important;
    }
    .nsdicon-close{
        float: left  !important;
    }
    }
    }


 }

 a.socialShare{
    padding: 0px 3px;
    word-wrap: normal;
    display: inline-block;
    i {
     box-shadow: 0px 3px 9px rgb(173 170 170 / 60%); 
    }
}

.tabHeader{
    color: #265999 !important;
    font-family: somar !important;
    .active{
        background-color: #eeeeee !important;
    }
}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
